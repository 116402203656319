<template>
  <div class="alter">
    <div id="map">
      <div id="tool-control" @click.stop="changeType($event)">
        <div class="tool-item" id="polygon">{{ drawStatus }}</div>
      </div>
    </div>
    <MyNav></MyNav>
    <div class="edit-map">
      <div class="add-form">
        <div class="cover">
          <p>土地封面</p>
          <el-upload
            :class="['uploadCover', imageUrl ? 'active' : '']"
            action="https://resource.darsing.net/api/v1/img"
            name="img"
            :limit="1"
            list-type="picture-card"
            :show-file-list="true"
            :file-list="fileList"
            :headers="uploadHeaders"
            :on-success="handleAvatarSuccess"
            :on-error="handleAvatarFail"
            :on-remove="removeFile"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <el-form :model="form" ref="form" :rules="rules" hide-required-asterisk>
          <el-form-item
            label="土地标题"
            :label-width="formLabelWidth"
            prop="title"
          >
            <el-input
              v-model.trim="form.title"
              autocomplete="off"
              placeholder="请输入土地标题"
              maxlength="20"
              @blur="getFormName"
            ></el-input>
          </el-form-item>
          <el-form-item label="简介" :label-width="formLabelWidth" prop="desc">
            <el-input
              v-model.trim="form.desc"
              autocomplete="off"
              placeholder="请输入简介"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item label="地块区域" :label-width="formLabelWidth">
            <div class="region">
              <p>*请从右侧编辑工具编辑</p>
            </div>
          </el-form-item>
          <el-form-item label="土地面积" :label-width="formLabelWidth">
            <div class="area">
              <p>{{ areaVal }}平米</p>
              <p class="tips">（根据圈地结果计算）</p>
            </div>
          </el-form-item>
          <el-form-item
            label="产品种植"
            class="type"
            :label-width="formLabelWidth"
            prop="product_title"
          >
            <div class="select-box">
              <el-select
                v-model="form.type"
                placeholder="选择品种分类"
                @change="getProductListApi(1, $event)"
                v-loadMore="loadMoreClassData"
              >
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="item in tabList"
                  :key="item.id"
                ></el-option>
              </el-select>
              <el-select
                v-model="form.product_title"
                placeholder="选择产品"
                @change="changeProduct"
                v-loadMore="loadMoreItemsData"
              >
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in cropsList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </div>
            <div class="product-box">
              <div
                class="product-div"
                v-for="(t, index) in multipleList"
                :key="index"
              >
                <span class="product-span"> {{ t }}</span>
                <i class="el-icon-close" @click="delProduct(t)"></i>
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="是否启用"
            :label-width="formLabelWidth"
            prop="custom_state"
          >
            <el-switch class="my-switch" v-model="form.custom_state">
            </el-switch>
          </el-form-item>
          <el-form-item
            label="填充颜色"
            :label-width="formLabelWidth"
            prop="body_color"
          >
            <div class="slider-box">
              <el-color-picker
                size="small"
                v-model="form.body_color"
                show-alpha
                @active-change="activeChange"
              ></el-color-picker>
              <!-- <span>透明度</span>
              <el-slider v-model="form.alpha" show-input></el-slider>
              <span>%</span> -->
            </div>
          </el-form-item>
          <el-form-item
            label="边界颜色"
            :label-width="formLabelWidth"
            prop="border_color"
          >
            <el-color-picker
              size="small"
              v-model="form.border_color"
              @active-change="activeChangeBorder"
            ></el-color-picker>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button class="ok-button" type="primary" @click="editMaps('form')"
          >确 定</el-button
        >
        <el-button class="no-button" @click="hideMaps('form')">取 消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getProductListApi,
  getProductClassListApi,
  postAddPlotApi,
  getPlotInfoApi,
  postEditPlotInfoApi,
  postEditPlotStateApi,
} from "@/request/api";
import { mapState } from "vuex";
import "ol/ol.css";
import "ol-ext/dist/ol-ext.min.css";
import { Map, View } from "ol";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import LayerVector from "ol/layer/Vector";
import SourceVector from "ol/source/Vector";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { Draw, Modify, defaults } from "ol/interaction";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
import * as turf from "@turf/turf";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },

  data() {
    return {
      map: null,
      draw: null,
      vector: null,
      source: new SourceVector({
        wrapX: false,
      }),
      plot_id: "",
      cropsList: [],
      multipleList: [],
      multipleIdList: [],
      tabList: [],
      cid: "",
      cropClassId: "",
      fileList: [],
      imageUrl: "",
      uploadHeaders: {
        Authorization: localStorage.getItem("darsingToken"),
      },
      form: {
        title: "",
        desc: "",
        type: "",
        product_title: "",
        product_id: "",
        custom_state: false,
        body_color: "rgba(255,255,255,0.4)",
        border_color: "#3E90E5",
        alpha: 30,
      },
      rules: {
        title: [{ required: true, message: "请输入土地标题", trigger: "blur" }],
        desc: [{ required: true, message: "请输入简介", trigger: "blur" }],
        // type: [{ required: true, message: "请选择种类", trigger: "change" }],
        // product_title: [
        //   { required: true, message: "请选择产品", trigger: "change" },
        // ],
        body_color: [
          { required: true, message: "请选择填充颜色", trigger: "change" },
        ],
        border_color: [
          { required: true, message: "请选择边界颜色", trigger: "change" },
        ],
      },
      formLabelWidth: "120px",
      drawStatus: "开始绘制",
      areaVal: "0",
      border_color: "",
      body_color: "",
      landName: "未编辑",
      pathsArr: [],
      centerVal: "",
      latNum: 0,
      lngNum: 0,
      classTotal: 10,
      classCurrent_page: 1,
      productTotal: 10,
      productCurrent_page: 1,
      position: "",
    };
  },
  watch: {
    "form.border_color": {
      handler(newVal) {
        if (this.vector) {
          this.vector
            .getSource()
            .getFeatures()[0]
            .setStyle(
              new Style({
                fill: new Fill({
                  color: this.form.body_color,
                }),
                stroke: new Stroke({
                  width: 4,
                  color: newVal,
                }),
              })
            );
        }
      },
      deep: true,
      immediate: true,
    },
    "form.body_color": {
      handler(newVal) {
        if (this.vector) {
          this.vector
            .getSource()
            .getFeatures()[0]
            .setStyle(
              new Style({
                fill: new Fill({
                  color: newVal,
                }),
                stroke: new Stroke({
                  width: 4,
                  color: this.form.border_color,
                }),
              })
            );
        }
      },
      deep: true,
      immediate: true,
    },
    landName() {
      this.getFormName();
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      farmInfo: (state) => state.user.farmInfo,
      uploadImgs: (state) => state.app.uploadImgs,
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    this.initMap();
  },
  created() {
    this.position = this.farmInfo.position || "";
    this.plot_id = +this.$route.query.type || "";
    this.latNum = this.position.split(",")[1];
    this.lngNum = this.position.split(",")[0];
    if (this.plot_id) {
      this.getPlotInfoApi(this.plot_id);
    }
    this.getProductClassListApi();
  },
  methods: {
    delProduct(title) {
      this.multipleIdList = this.multipleIdList.filter((item) => {
        return item !== this.multipleIdList[this.multipleList.indexOf(title)];
      });
      this.multipleList = this.multipleList.filter((item) => {
        return item !== title;
      });

      if (this.form.product_title === title) {
        this.form.product_title = this.multipleList.at(-1);
      }
    },
    rgbaNum(rgba, index) {
      let val = rgba.match(/(\d(\.\d+)?)+/g);
      return val[index];
    },
    activeChangeBorder(e) {
      this.form.border_color = e;
    },
    activeChange(e) {
      this.form.body_color = e;
      let alpha = this.rgbaNum(this.form.body_color, 3);
      this.form.alpha = Math.round(alpha * 100);
    },
    featureStyle(x, y) {
      let style = new Style({
        fill: new Fill({
          color: y,
        }),
        stroke: new Stroke({
          width: 4,
          color: x,
        }),
      });
      return style;
    },
    showPolygon() {
      this.source.clear();
      let array = this.pathsArr;
      let features = [];
      for (let i = 0; i < array.length; i++) {
        let coord = array[i].coord;
        let x = array[i].color[0];
        let y = array[i].color[1];
        let z = array[i].name;
        let f = new Feature({
          geometry: new Polygon([coord]),
          type: "isPolygon",
        });
        f.setStyle(this.featureStyle(x, y));
        f.setProperties({
          name: z,
        });
        features.push(f);
      }
      this.source.addFeatures(features);
    },
    drawPolygon() {
      if (this.draw !== null) {
        this.map.removeInteraction(this.draw);
      }
      this.draw = new Draw({
        source: this.source,
        type: "Polygon",
      });
      this.map.addInteraction(this.draw);
      this.draw.on("drawend", (e) => {
        let feature = e.feature;
        let geom = feature.getGeometry();
        let i2 = geom.getCoordinates()[0];
        this.pathsArr = i2;
        this.showArea(i2);
        this.map.removeInteraction(this.draw);
        this.startModify();
      });
    },

    startModify() {
      this.modify = new Modify({
        source: this.source,
        type: "Polygon",
      });
      this.map.addInteraction(this.modify);
      this.modify.on("modifyend", (e) => {
        let modifyGeom = e.features.array_[0].getGeometry();
        let i2 = modifyGeom.getCoordinates()[0];
        this.showArea(i2);
        this.pathsArr = i2;
      });
    },
    endModify() {
      if (this.modify !== null) {
        this.map.removeInteraction(this.modify);
      }
    },
    showArea(i2) {
      let polygon = turf.polygon([i2]);
      this.areaVal = Math.round(turf.area(polygon));
      this.centerVal = turf.centroid(polygon).geometry.coordinates;
    },

    initMap() {
      this.vector = new LayerVector({
        zIndex: 3,
        source: this.source,
        style: new Style({
          fill: new Fill({
            color: this.form.body_color,
          }),
          stroke: new Stroke({
            width: 4,
            color: this.form.border_color,
          }),
        }),
      });
      this.map = new Map({
        target: "map",
        layers: [
          new TileLayer({
            zIndex: 1,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          new TileLayer({
            zIndex: 2,
            preload: Infinity,
            source: new OSM({
              url: `https://tiles1.geovisearth.com/base/v1/cat/{z}/{x}/{y}?format=webp&tmsIds=w&secretId=${this.userInfo.hmac_sign.secretId}&clientId=${this.userInfo.hmac_sign.clientId}&expireTime=${this.userInfo.hmac_sign.expireTime}&sign=${this.userInfo.hmac_sign.sign}`,
            }),
          }),
          this.vector,
        ],
        view: new View({
          projection: "EPSG:4326",
          center: [+this.lngNum, +this.latNum],
          zoom: 13,
          maxZoom: 20,
          constrainResolution: true,
          smoothResolutionConstraint: false,
          extent: [73.66, 3.86, 135.05, 53.55], //中国边界
        }),
        interactions: new defaults({
          doubleClickZoom: false,
        }),
      });
    },
    async getPlotInfoApi(plot_id) {
      const { code, results } = await getPlotInfoApi({ plot_id });
      if (code === 0) {
        let polygonsItemObj = { coord: [] };
        this.form = results;
        this.form.product_title = results.product_list.at(-1).title;
        results.product_list.forEach((item) => {
          this.multipleList.push(item.title);
          this.multipleIdList.push(item.product_id);
        });
        if (results.photo) {
          this.imageUrl = results.photo;
          this.fileList.push({
            url: this.uploadImgs + results.photo,
          });
        }
        this.form.custom_state = results.custom_state === 1 ? true : false;
        this.areaVal = results.area;
        let mark_data;
        let mark_data2;
        if (typeof results.mark_data == "string") {
          mark_data2 = JSON.parse(results.mark_data)[0];
          if (mark_data2.name) {
            mark_data = mark_data2.coord;
          } else {
            mark_data = JSON.parse(results.mark_data);
          }
        } else {
          mark_data = results.mark_data;
        }
        this.form.mark_data = mark_data;
        this.$forceUpdate();
        this.latNum = this.position.split(",")[1];
        this.lngNum = this.position.split(",")[0];
        mark_data.forEach((item3) => {
          polygonsItemObj.coord.push([item3[0], item3[1]]);
        });
        polygonsItemObj.color = [results.border_color, results.body_color];
        polygonsItemObj.name = `polygon${results.id}`;
        this.pathsArr.push(polygonsItemObj);
        if (this.pathsArr.length) {
          this.showPolygon();
          this.startModify();
        }
      }
    },
    loadMoreClassData() {
      if (this.tabList.length && this.tabList.length == this.classTotal) return;
      this.classCurrent_page++;
      this.getProductClassListApi(this.classCurrent_page);
    },
    loadMoreItemsData() {
      if (this.cropsList.length && this.cropsList.length == this.productTotal)
        return;
      this.productCurrent_page++;
      this.getProductListApi(this.productCurrent_page, this.cropClassId);
    },
    async getProductListApi(pn = 1, id) {
      this.form.product_title = "";
      this.form.product_id = "";
      this.cropClassId = id;
      this.cropsList = [];
      const { code, results } = await getProductListApi({
        manor_id: this.farmID,
        cid: id,
        pn,
      });
      if (code === 0) {
        this.cropsList = [...this.cropsList, ...results.data];
        this.productCurrent_page = results.pn;
        this.productTotal = results.count;
      }
    },
    changeProduct(id) {
      this.form.product_title = this.cropsList.find((item) => {
        return item.id == id;
      }).title;
      if (this.multipleList.indexOf(this.form.product_title) == -1) {
        this.multipleList.push(this.form.product_title);
      }
      if (this.multipleIdList.indexOf(id) == -1) {
        this.multipleIdList.push(id);
      }
    },
    async getProductClassListApi(pn = 1) {
      const { code, results } = await getProductClassListApi({
        manor_id: this.farmID,
        pn,
      });
      if (code === 0) {
        this.tabList = [...this.tabList, ...results.data];
        this.classCurrent_page = results.pn;
        this.classTotal = results.count;
      }
    },
    removeFile() {
      this.imageUrl = "";
    },
    handleAvatarFail() {
      this.$notify.error({
        title: "错误",
        message: "上传失败，稍后尝试",
      });
    },
    handleAvatarSuccess(res, file) {
      if (file.percentage === 100) {
        this.imageUrl = file.response.Data.imgid;
        this.form.photo = file.response.Data.imgid;
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
        });
      }
    },
    async postAddPlotApi() {
      this.form.product_ids = this.multipleIdList.join(",");
      this.form.custom_state = this.form.custom_state ? 1 : 0;
      let mark_data = JSON.stringify(this.pathsArr);
      const { code } = await postAddPlotApi({
        manor_id: this.farmID,
        area: this.areaVal,
        mark_type: 3,
        mark_data,
        weight: 0,
        ...this.form,
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
        this.$router.push({
          name: "land",
        });
      }
    },
    async postEditPlotStateApi(custom_state) {
      await postEditPlotStateApi({
        plot_id: this.plot_id,
        custom_state,
      });
    },
    async postEditPlotInfoApi() {
      this.form.product_ids = this.multipleIdList.join(",");
      this.form.custom_state = this.form.custom_state ? 1 : 0;
      if (this.pathsArr[0].name) {
        this.form.mark_data = JSON.stringify(this.pathsArr[0].coord);
      } else {
        this.form.mark_data = JSON.stringify(this.pathsArr);
      }
      const { code } = await postEditPlotInfoApi({
        area: this.areaVal,
        manor_id: this.farmID,
        plot_id: this.plot_id,
        photo: this.imageUrl,
        mark_type: 3,
        weight: 0,
        body_color: this.form.body_color,
        border_color: this.form.border_color,
        alpha: this.form.alpha,
        custom_state: this.form.custom_state,
        desc: this.form.desc,
        id: this.form.id,
        mark_data: this.form.mark_data,
        product_ids: this.form.product_ids,
        title: this.form.title,
        state: this.form.state,
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
        this.$router.push({
          name: "land",
        });
      }
    },
    editMaps(formName) {
      let custom_state = this.form.custom_state ? 1 : 0;
      this.$refs[formName].validate((valid) => {
        if (!valid) return;
        if (this.plot_id) {
          this.postEditPlotStateApi(custom_state);
          this.postEditPlotInfoApi();
        } else {
          this.postAddPlotApi();
        }
      });
    },
    hideMaps() {
      this.$router.push({
        path: "land",
      });
    },

    createTitleStyle({ text }) {
      return new Style({
        text: new Text({
          font: "16px sans-serif",
          text,
          fill: new Fill({
            color: "#fff",
          }),
          backgroundFill: new Fill({
            color: "#032646",
          }),
          padding: [2, 2, 2, 2],
        }),
      });
    },
    getFormName() {
      this.landName = this.form.title;
    },
    changeType() {
      if (!this.form.body_color || !this.form.border_color) {
        this.$notify.error({
          title: "未选择填充颜色、边界颜色或透明度",
        });
        return;
      } else if (this.pathsArr.length) {
        return;
      } else {
        this.drawStatus = "绘制中...";
        this.drawPolygon();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.edit-map {
  height: 100vh;
  width: 558px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  border-right: 2px solid #071a2c;
  padding: 132px 55px 0 55px;
  background: url(../assets/image/land/editbgimg.jpg) center center no-repeat
    #0e2138;
  background-size: cover;
  color: #fff;
  .add-form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .cover {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-bottom: 43px;

      p {
        font-size: 14px;
        width: 120px;
        padding: 0 24px;
        text-align: right;
      }

      .cover-img {
        width: 158px;
        height: 105px;
      }
      /deep/.uploadCover {
        width: 158px;
        height: 105px;
        display: flex;
        .el-upload--picture-card {
          width: 100%;
          height: 100%;
          line-height: 105px;
        }
        .el-upload-list--picture-card .el-upload-list__item {
          width: 158px;
          height: 105px;
        }
        &.active {
          .el-upload--picture-card {
            display: none;
          }
        }
      }
    }

    .area {
      display: flex;
      align-items: center;

      .tips {
        color: #155ca5;
        font-size: 12px;
        margin-left: 11px;
      }
    }
    .slider-box {
      width: 100%;
      display: flex;
      align-items: center;
      color: #3e90e5;
      font-size: 12px;
      /deep/.el-input {
        width: 45px;
      }
    }
    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
      flex: 1;
    }
    /deep/.el-form-item__label {
      font-size: 14px;
      color: #fff;
      padding: 0 24px 0 0;
    }

    /deep/.type .el-form-item__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    /deep/.type .el-select {
      width: 166px;
      margin-right: 18px;
    }
    /deep/.type .el-select:nth-of-type(2) {
      margin-right: 0px;
    }
    .select-box {
      width: 100%;
      display: flex;
    }
    .product-box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      .product-div {
        margin: 0 8px 0 0;
        .product-span {
          font-size: 14px;
          color: #fff;
          margin-right: 4px;
        }
        .el-icon-close {
          font-size: 16px;
          color: #409eff;
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
      }
    }
  }

  /deep/.el-select-dropdown {
    z-index: 10000 !important;
  }
  /deep/.el-color-picker__trigger {
    padding: 0;
    margin-right: 30px;
  }
  /deep/.el-slider.el-slider--with-input {
    flex: 1;
    margin-left: 16px;
    .el-slider__runway.show-input {
      margin-right: 65px;
    }
    .el-slider__input.el-input-number.el-input-number--small {
      width: 45px;
      margin-right: 7px;
      .el-input-number__increase,
      .el-input-number__decrease {
        display: none;
      }
      .el-input__inner {
        padding: 0;
        color: #3e90e5;
      }
    }
  }
  /deep/.dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ok-button {
    width: 150px;
    height: 40px;
    margin-right: 54px;
  }

  .no-button {
    width: 150px;
    height: 40px;
    border: 1px solid #385576;
    background-color: #0f243d;
  }
}
#map {
  width: 100%;
  height: 100vh;
  position: relative;
  #tool-control,
  #tool-control2 {
    position: absolute;
    top: 80px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 252px;
    z-index: 2001;
    display: flex;
    align-items: center;
    .tool-item {
      width: 50px;
      height: 50px;
      padding: 5px;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid #fff;
      color: #666;
      font-size: 14px;
      cursor: pointer;
      line-height: 20px;
      text-align: center;
      &:hover {
        border-color: #789cff;
      }
    }
  }
  #tool-control2 {
    left: 160px;
  }
}
</style>
